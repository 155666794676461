exports.components = {
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-portfolio-js": () => import("./../../../src/pages/de/portfolio.js" /* webpackChunkName: "component---src-pages-de-portfolio-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-portfolio-js": () => import("./../../../src/pages/en/portfolio.js" /* webpackChunkName: "component---src-pages-en-portfolio-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-kontakt-js": () => import("./../../../src/pages/hr/kontakt.js" /* webpackChunkName: "component---src-pages-hr-kontakt-js" */),
  "component---src-pages-hr-portfolio-js": () => import("./../../../src/pages/hr/portfolio.js" /* webpackChunkName: "component---src-pages-hr-portfolio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-portfolio-js": () => import("./../../../src/pages/pl/portfolio.js" /* webpackChunkName: "component---src-pages-pl-portfolio-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

